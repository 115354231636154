var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"p-2 p-lg-3 my-1 my-lg-3 text-primary",staticStyle:{"cursor":"pointer"}},[_vm._v(" Inventori ")]),_c('router-link',{attrs:{"to":"/inventories"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover",class:[
        isActive && 'menu-list-active',
        isExactActive && 'menu-list-active',
      ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-pallet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Inventori ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}})])])])])]}}])}),_c('router-link',{attrs:{"to":"/inventory-stocks"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover",class:[
        isActive && 'menu-list-active',
        isExactActive && 'menu-list-active',
      ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-boxes text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Kartu Stok ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}})])])])])]}}])}),_c('router-link',{attrs:{"to":"/inventory-categories"}},[_c('div',{staticClass:"list list-hover text-classroom-hover"},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-table text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Kategori ")])])])])])]),_c('router-link',{attrs:{"to":"/uoms"}},[_c('div',{staticClass:"list list-hover text-classroom-hover"},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-balance-scale text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Satuan ")])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }