<template>
  <ul class="menu-nav">
    <li class="menu-section" >
      <h4 class="menu-text">Master</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/masters/users"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="user"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">User</span>
        </a>
      </li>
    </router-link>

    <!-- <li class="menu-section">
      <h4 class="menu-text">Master Khusus</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li> -->

    <router-link
      to="/masters/roles"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="role"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-circle"></i>
          <span class="menu-text">Peran</span>
        </a>
      </li>
    </router-link>
   
    <router-link
      to="/masters/category-citizen-report"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="role"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-comment-dots"></i>
          <span class="menu-text">Kategori Pengaduan Masyarakat</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/news-category"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="role"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="far fa-newspaper menu-icon"></i>
          <span class="menu-text">Kategori Berita</span>
        </a>
      </li>
    </router-link>
    
    <router-link
      to="/masters/village-business-category"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="role"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-store menu-icon"></i>
          <span class="menu-text">Jenis Bidang Usaha</span>
        </a>
      </li>
    </router-link>
    
    <router-link
      to="/masters/infrastructure-category"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="role"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="fas fa-university menu-icon"></i>
          <span class="menu-text">Jenis Sarana</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      to="/masters/urban-village"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="role"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-circle"></i>
          <span class="menu-text">Dusun</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      to="/masters/hamlets"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="role"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-city"></i>
          <span class="menu-text">Rukun Warga</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/profession"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon fas fa-city"></i> -->
          <i class="menu-icon fas fa-briefcase"></i>
          <span class="menu-text">Pekerjaan</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/law-product-categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon fas fa-city"></i> -->
          <i class="menu-icon fas fa-briefcase"></i>
          <span class="menu-text">Kategori Produk Hukum</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      to="/masters/announcements"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-bullhorn"></i>
          <span class="menu-text">Pengumuman</span>
        </a>
      </li>
    </router-link> -->

    <li class="menu-section" v-if="settingTitle">
      <h4 class="menu-text">Setting</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      to="/settings/edit/1"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="settingView"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-gear"></i>
          <span class="menu-text">Pengaturan CMS</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/user-managements/role-access"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="roleAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-cog"></i>
          <span class="menu-text">Hak Akses Peran</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/user-managements/user-access"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="userAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users-cog"></i>
          <span class="menu-text">Hak Akses User</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "Master",
  data(){
    return{
      // access
      // master
      masterTitle: true,
      role: true,
      user: true,
      // setting
      settingTitle: true,
      settingView: true,
      roleAccess: true,
      userAccess: true,
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    // access
    checkAccess() {
      
    },
  },
  mounted(){
    this.checkAccess()
    this.$root.$on("refreshMasterAside", () => {
      //('refreshMasterAside')
      this.checkAccess();
    });
  }
};
</script>
