<template>
  <div>
    <!-- <router-link to="/classrooms"> -->
    <h3 class="p-2 p-lg-3 my-1 my-lg-3 text-primary" style="cursor: pointer">
      Bansos
    </h3>
    <!-- </router-link> -->
    <!--begin::List-->
      <router-link to="/materials" v-slot="{ href, navigate, isActive, isExactActive }">
        <div class="list list-hover text-classroom-hover" :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]">
          <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="fas fa-pallet text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                 Persediaan Bahan
                </span>
                <a :href="href" class="text-dark-75"></a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/material-stocks" v-slot="{ href, navigate, isActive, isExactActive }">
        <div class="list list-hover text-classroom-hover" :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]">
          <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="fas fa-boxes text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Stok Bahan
                </span>
                <a :href="href" class="text-dark-75"></a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/material-categories">
        <div class="list list-hover text-classroom-hover">
          <div class="list-item hoverable p-2 p-lg-3 mb-2">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="fas fa-table text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Kategori
                </span>
                <!-- <a href="#" class="text-dark-75">Menu 1</a> -->
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--begin::List-->
      <router-link to="/material-distributions">
        <div class="list list-hover text-classroom-hover">
          <div class="list-item hoverable p-2 p-lg-3 mb-2">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="fas fa-table text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Distribusi
                </span>
                <!-- <a href="#" class="text-dark-75">Menu 1</a> -->
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
export default {
  data() {
    return {
      // access
    };
  },
  methods: {
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1001") {
        }
        if (access_right[a] == "1002") {
          this.btn = true;
        }
        if (access_right[a] == "1003") {
          this.btnJoin = true;
        }
      }
    },
  },
  mounted() {
    this.checkAccess();
  },
};
</script>

<style scoped>



.text-custom {
  color: #38c762;
}

.icon-size {
  font-size: 2.3rem;
}

.menu-list-active {
  background-color: #ffffff;
}
.menu-list-active span {
  color: #38c762 !important;
}
.menu-list-active a {
  color: #38c762 !important;
}
.menu-list-active i {
  color: #38c762 !important;
}

.text-classroom-hover:hover .text-primary {
  color: #38c762 !important;
}

.text-classroom-hover:hover span.text-dark-75 {
  color: #38c762 !important;
}

.text-classroom-hover:hover i{
  color: #38c762 !important;
}
</style>