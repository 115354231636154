<template>
  <div>
    <div class="p-2 p-lg-3">
      <router-link class="btn btn-sm btn-info" to="/classrooms/add" v-if="btn"
        >Tambah Kelas</router-link
      >
      <!-- <router-link
        class="btn btn-sm btn-success ml-2"
        to="/classrooms/join"
        v-if="btnJoin"
        >Gabung Kelas</router-link
      > -->
    </div>

    <!-- <router-link to="/classrooms"> -->
      <h3 
        class="p-2 p-lg-3 my-1 my-lg-3 text-hover-info"
        style="cursor: pointer" 
        @click="handleMyClass"
      >Kelas Saya</h3>
    <!-- </router-link> -->
    <!--begin::List-->
    <template v-for="item in items">
      <router-link :key="item.id" :to="'/classrooms/detail/' + item.id">
        <div class="list list-hover text-classroom-hover">
          <!--begin::Item-->
          <div class="list-item hoverable p-2 p-lg-3 mb-2">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white">
                  <!-- <inline-svg
                        src="media/svg/misc/006-plurk.svg"
                        class="h-50 align-self-center"
                      /> -->
                  <img
                    :src="item.image_thumbnail"
                    class="h-75 align-self-center"
                  />
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-dark-75 mb-0 text-hover-primary">
                  {{ subsRoom(item.room) }}
                </span>
                <a
                  href="#"
                  class="text-muted"
                >
                  {{ item.teacher_name }}
                </a>
              </div>
              <!--begin::End-->
            </div>
          </div>
          <!--end::Item-->
        </div>
      </router-link>
    </template>
    <!--end::List-->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import api from "@/core/services/api.service.js";
export default {
  name: "ClassMenu",
  data() {
    return {
      items: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // access
      classroomView: false,
      btn: false,
      btnJoin: false,
    };
  },
  methods: {
    async pagination() {
      api.get("users/me").then((response) => {
        api
          .get(`api/classrooms/my-classrooms/${response.data.data.id}`)
          .then((result) => {
            this.items = result.data.data;

            //   for(let a = 0; a < this.items.length; a++){
            //     let name = this.items[a].room.split(" ")
            //     this.items[a].room_display = ""
            //     for(let b = 0; b < name.length; b++){
            //       if(name[b].length > 9){
            //           this.items[a].room_display += name[b].substring(0, 9) + "... "
            //       }else{
            //         if(b + 1 == name.length){
            //           this.items[a].room_display += name[b]
            //         }else{
            //           this.items[a].room_display += name[b] + " "
            //         }
            //       }
            //     }

            //   }
          });
      });
    },
    handleMyClass() {
      this.$root.$emit("autoSaveStop");
      this.$router.push({path: '/classrooms'})
    },

    subsRoom(room) {
      let name = room.length > 19 ? room.substr(0, 19) + "..." : room;
      return name;
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1001") {
          this.classroomView = true;
        }
        if (access_right[a] == "1002") {
          this.btn = true;
          this.classroomView = true;
        }
        if (access_right[a] == "1003") {
          this.btnJoin = true;
          this.classroomView = true;
        }
      }
    },
  },
  // created() {
  //   this.pagination();
  // },
  mounted() {
    // this.pagination();
    // this.checkAccess();
    // this.$root.$on("refreshClassroom", () => {
    //   this.pagination();
    // });
    // this.$root.$on("refreshClassroomAside", () => {
    //   this.checkAccess();
    // });
  },
};
</script>

<style scoped>
.text-classroom-hover:hover{
  font-weight: bold;
}
</style>