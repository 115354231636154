<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <a href="/">
      <img
        alt="Logo"
        :src="config.city_logo"
        class="logo-default max-h-30px"
      />
    </a>
    <!--end::Logo-->
    <!--begin::Name-->
    <div class="d-flex align-items-center">
      <span>
        <span class="d-block font-weight-bolder mr-2">{{
          currentUser.name
        }}</span>
        <span
          class="mr-2 text-danger font-size-sm"
          v-if="
            currentUser.role_key_name != 'super_admin' ||
            currentUser.user_type_id != 3
          "
        >
          {{ currentUser.posyandu_name }}
        </span>
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <span class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUser.name.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>
    <!--end::Name-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <button
        class="btn p-0 burger-icon burger-icon-left"
        id="kt_aside_mobile_toggle"
      >
        <span></span>
      </button>
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import { getConfiguration } from "@/core/services/jwt.service.js";

export default {
  name: "KTHeaderMobile",
  components: {},
  data() {
    return {
      currentUser: getUser(),
      config: getConfiguration()
    };
  },
  methods: {
    async get() {
      this.currentUser = await module.get("users/" + getUser().id);
    },
  },
  mounted() {
    // this.get()
    // this.$root.$on("refreshCurrentUser", () => {
    //   this.get()
    // });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },
  },
};
</script>
