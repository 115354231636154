var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_vm._m(0),(_vm.user)?_c('router-link',{attrs:{"to":"/masters/users"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users"}),_c('span',{staticClass:"menu-text"},[_vm._v("User")])])])]}}],null,false,3166894752)}):_vm._e(),(_vm.role)?_c('router-link',{attrs:{"to":"/masters/roles"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v("Peran")])])])]}}],null,false,3621349941)}):_vm._e(),(_vm.role)?_c('router-link',{attrs:{"to":"/masters/category-citizen-report"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-comment-dots"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kategori Pengaduan Masyarakat")])])])]}}],null,false,378635457)}):_vm._e(),(_vm.role)?_c('router-link',{attrs:{"to":"/masters/news-category"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"far fa-newspaper menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kategori Berita")])])])]}}],null,false,280260106)}):_vm._e(),(_vm.role)?_c('router-link',{attrs:{"to":"/masters/village-business-category"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-store menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Jenis Bidang Usaha")])])])]}}],null,false,285317406)}):_vm._e(),(_vm.role)?_c('router-link',{attrs:{"to":"/masters/infrastructure-category"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-university menu-icon"}),_c('span',{staticClass:"menu-text"},[_vm._v("Jenis Sarana")])])])]}}],null,false,994953762)}):_vm._e(),(_vm.role)?_c('router-link',{attrs:{"to":"/masters/hamlets"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Rukun Warga")])])])]}}],null,false,179722561)}):_vm._e(),_c('router-link',{attrs:{"to":"/masters/profession"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-briefcase"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pekerjaan")])])])]}}])}),_c('router-link',{attrs:{"to":"/masters/law-product-categories"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-briefcase"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kategori Produk Hukum")])])])]}}])}),(_vm.settingTitle)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Setting")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.settingView)?_c('router-link',{attrs:{"to":"/settings/edit/1"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-gear"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pengaturan CMS")])])])]}}],null,false,154522027)}):_vm._e(),(_vm.roleAccess)?_c('router-link',{attrs:{"to":"/masters/user-managements/role-access"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hak Akses Peran")])])])]}}],null,false,3050360993)}):_vm._e(),(_vm.userAccess)?_c('router-link',{attrs:{"to":"/masters/user-managements/user-access"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hak Akses User")])])])]}}],null,false,2868451339)}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Master")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
}]

export { render, staticRenderFns }