<template>
  <div>
    <a
      href="#"
      class="btn btn-icon btn-clean btn-lg w-40px h-40px text-hover-primary py-4"
      id="kt_quick_user_toggle"
      data-toggle="tooltip"
      data-placement="right"
      data-container="body"
      data-boundary="window"
      title=""
      data-original-title="Profil Pengguna"
    >
      <span class="symbol symbol-30 symbol-lg-40">
        <span class="svg-icon svg-icon-xl svg-icon-white">
          <!--begin::Svg Icon-->
          <!-- <inline-svg :src="base_url + 'media/svg/icons/General/User.svg'" /> -->
          <i class="fas fa-user text-white"></i>
          <!--end::Svg Icon-->
        </span>
        <!--<span class="symbol-label font-size-h5 font-weight-bold">S</span>-->
        <marquee class="text-white d-block">Hi, {{currentUser.name}}</marquee>
      </span>
    </a>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-left p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
          <!-- <small class="text-muted font-size-sm ml-2">12 messages</small> -->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <!-- <img class="symbol-label" :src="currentUserPersonalInfo.photo" alt="" /> -->
            <!-- <img class="symbol-label" :src="currentUser.photo" alt="" /> -->
            <img class="symbol-label" :src="'/img/default/default-user.svg'" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <!-- <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ currentUserPersonalInfo.name }}
            </a> -->
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ currentUser.name }}
            </a>
            <!-- <div class="text-muted mt-1">{{currentUserPersonalInfo.role_name}}</div> -->
            <div class="text-muted mt-1">{{currentUser.role_name}}</div>
            <div class="navi mt-2" v-if="currentUser.email">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-white">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="base_url + 'media/svg/icons/Communication/Mail-notification.svg'"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <!-- <span class="navi-text text-muted text-hover-primary">
                    {{ currentUserPersonalInfo.email }}
                  </span> -->
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <!-- <router-link
            to="/profile"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <inline-svg
                      :src="base_url + 'media/svg/icons/General/Notification2.svg'"
                    />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Profil Saya</div>
                <div class="text-muted">
                  Pengaturan Profil
                </div>
              </div>
            </div>
          </router-link> -->
          <!--end:Item-->
          <!--begin::Item-->
          <!-- <router-link
            to="#"
            @click.native="closeOffcanvasMessage"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-warning">
                    <inline-svg :src="base_url + 'media/svg/icons/Communication/Mail-opened.svg'" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Pesan Saya</div>
                <div class="text-muted">Chat</div>
              </div>
            </div>
          </router-link> -->
          <!--end:Item-->
        </div>
        <!--end::Nav-->
        <div class="separator separator-dashed my-7"></div>
        <!--begin::Notifications-->
        <div>
          <!--begin:Heading-->
          <!-- <h5 class="mb-5">Pengumuman Terbaru</h5> -->
          <!--end:Heading-->
          <template v-for="(item, i) in list">
            <!--begin::Item -->
            <div
              class="d-flex align-items-center rounded p-5 gutter-b"
              v-bind:class="`bg-light-${item.type}`"
              v-bind:key="i"
              @click="showModal(item.id)"
            >
              <span
                class="svg-icon mr-5"
                v-bind:class="`svg-icon-${item.type}`"
              >
                <span class="svg-icon svg-icon-lg">
                  <!--begin::Svg Icon-->
                  <inline-svg src="/media/svg/icons/Code/Info-circle.svg" />
                  <!--end::Svg Icon-->
                </span>
              </span>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                  href="#"
                  class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {{ item.title }}
                </a>
                <span class="text-muted font-size-sm">
                  {{ item.content.length > 30 ? item.content.substr(0, 30) + '...' : item.content }}
                </span>
              </div>
              <!-- <span
                class="font-weight-bolder py-1 font-size-lg"
                v-bind:class="`text-${item.type}`"
              >
                {{ item.alt }}
              </span> -->
            </div>
            <!--end::Item -->
          </template>
        </div>
        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>

    <!--Start::Modal Announcement -->
      <b-modal ref="modal-announcement-show" size="lg" hide-footer :title="detailAnnouncement.title">
      <div class="d-block text-center">
        <!-- <h3 class="mb-4">{{detailAnnouncement.title}}</h3> -->
        <img v-if="detailAnnouncement.image" :src="detailAnnouncement.image" width="400" class="mb-10">
        <div class="mt-10" v-html="detailAnnouncement.content"></div>
      </div>
      <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button> -->
    </b-modal>
      <!--End::Modal Announcement -->
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import { mapGetters } from 'vuex'
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ApiService from "@/core/services/api.service.js";
import Swal from "sweetalert2";
import { destroyToken, destroyUser, destroyAccess } from "@/core/services/jwt.service.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      DataUser: {},
      currentUser: {},
      roles: {},
      base_url: process.env.BASE_URL,
      // list: [
      //   {
      //     title: "Another purpose persuade",
      //     desc: "Due in 2 Days",
      //     alt: "+28%",
      //     svg: "media/svg/icons/Home/Library.svg",
      //     type: "warning",
      //   },
      //   {
      //     title: "Would be to people",
      //     desc: "Due in 2 Days",
      //     alt: "+50%",
      //     svg: "media/svg/icons/Communication/Write.svg",
      //     type: "success",
      //   },
      //   {
      //     title: "Purpose would be to persuade",
      //     desc: "Due in 2 Days",
      //     alt: "-27%",
      //     svg: "media/svg/icons/Communication/Group-chat.svg",
      //     type: "danger",
      //   },
      //   {
      //     title: "The best product",
      //     desc: "Due in 2 Days",
      //     alt: "+8%",
      //     svg: "media/svg/icons/General/Attachment2.svg",
      //     type: "info",
      //   },
      // ],
      list: [],
      detailAnnouncement: {}
    };
  },
  methods: {
    async get() {
      this.currentUser = await module.get('users/' + getUser().id)
    },
    onLogout() {
      ApiService.put(`users/${getUser().id}/set-user-off`).then((response)=>{
          this.$root.$emit('stopTimeLog')
          this.$store
          .dispatch(LOGOUT)
          .then(() => {
          this.$router.push({ name: "login" })
          destroyToken()
          destroyUser()
          window.localStorage.removeItem("RefreshToken");
          window.localStorage.removeItem("access_right_display");
          window.localStorage.removeItem("access_right");
          });
        }).catch((error) => {
          //('error set on')
          this.$root.$emit('refreshUserOnline')
        })
      // this.$router.push({ name: "login" })
      //     destroyToken()
      //     destroyUser()
      //     window.localStorage.removeItem("access_right_display");
      //     window.localStorage.removeItem("access_right");
    },
    closeOffcanvas() {
      this.$root.$emit('openActivityUser')
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    closeOffcanvasMessage() {
      this.$root.$emit('openChatAside')
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    async getAnnouncement() {
      let filterParams = ``;
      let response = await module.paginate(
        "api/announcements/get-shows"
      );

      for (let a = 0; a < response.data.length; a++) {
        let number = Math.random()
          response.data[a].type = 'warning';
        if(number < 0.34){
          response.data[a].type = 'info';
        }else if(number >= 0.34 && number < 0.67){
          response.data[a].type = 'primary';
        }else{
          response.data[a].type = 'success';
        }
      }

      this.list = response.data;
    },
    async showModal(id) {
     this.detailAnnouncement = await module.get('api/announcements/' + id)
        //("detail announcements", this.detailAnnouncement)
        this.$refs['modal-announcement-show'].show()
    },
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    // this.getAnnouncement()
    this.get()

    this.$root.$on("refreshCurrentUser", () => {
      this.get()
    });

    // this.$root.$on("refreshAnnouncementActive", () => {
    //   this.getAnnouncement()
    // });
  },
  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    ...mapGetters(["currentUserPersonalInfo"])
  },
};
</script>
